@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-x: hidden;
  background-color: #f9f9f9;
}

#scroll::-webkit-scrollbar {
  width: 7px;
}

#scroll::-webkit-scrollbar-thumb {
  background-color: #08b295;
  border-radius: 10px;
}

#scroll::-webkit-scrollbar-track {
  background-color: #f1f1f190;
}

#content {
  width: 250px;
  font-size: 15px;
  padding: 20px;
}

#title {
  font-weight: 600;
}

#address {
  font-size: 12px;
}

#btn {
  display: flex;
  margin-top: 12px;
  color: #08b295;
  font-weight: 600;
}

#robotBtn {
  padding-right: 10px;
}

.no-map-url {
  position: relative;
  min-height: 300px;

  &::after {
    content: "지도 이미지가 없습니다.";
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-color: #000;
    color: #fff;
  }
}

.map-splash-container {
  width: 594px;
  height: 297px;
  background-color: #0b0d0f;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  .map-splash-inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 25px;

    > div {
      position: relative;
    }

    .map-splash-bg {
      max-width: 200px;
    }

    .map-splash-arrow {
      max-width: 36px;
      position: absolute;
      animation-duration: 1.5s;
      animation-name: arrow-move;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }

    .map-splash-title {
      max-width: 236px;
    }
  }
}

@keyframes arrow-move {
  from {
    top: 80px;
    left: 19.5px;
  }

  to {
    top: 40px;
    left: 152px;
  }
}

.ag-header-cell-label {
  justify-content: center !important;
}