.CFaArrowLeft {
    width: 18.67px;
    height: 18.15px;

    @media (max-width: 1920px) {
        width: 0.971vw;
        height: 0.944vw;
    }
}

.CFaChevronRight {
    width: 11.11px;
    height: 18px;

    @media (max-width: 1920px) {
        width: 0.578vw;
        height: 0.938vw;
    }
}

.CFaChevronLeft {
    width: 11.11px;
    height: 18px;

    @media (max-width: 1920px) {
        width: 0.578vw;
        height: 0.938vw;

    }
}

.CMobileFaArrowLeft {
    width: 18.67px;
    height: 18.15px;

    @media (max-width: 1920px) {
        width: 2.43021vw;
        height: 2.36198vw;
    }
}

.CMobileFaChevronRight {
    width: 11.11px;
    height: 18px;

    @media (max-width: 1920px) {
        width: 1.44401vw;
        height: 2.34375vw;
    }
}

.CMobileFaChevronLeft {
    width: 11.11px;
    height: 18px;

    @media (max-width: 1920px) {
        width: 1.44401vw;
        height: 2.34375vw;
    }
}