@charset "UTF-8";
#operationVideo .robot-state-stage {
  padding-bottom: 20px;
  border-bottom: 1px dashed #dadfe4;
}
#operationVideo .robot-state-stage .stage-container {
  position: relative;
  /* width: 400px; */
  margin: 0 auto;
}
#operationVideo .robot-state-stage .stage-container .line {
  position: absolute;
  top: 33%;
  left: 9%;
  width: 294px;
  height: 1px;
  background-color: #e8eaec;
}
#operationVideo .robot-state-stage .stage-container ul {
  position: relative;
  display: flex;
  justify-content: center;
}
#operationVideo .robot-state-stage .stage-container ul li {
  width: 78px;
  margin-right: 22px;
  text-align: center;
}
#operationVideo .robot-state-stage .stage-container ul li.active div {
  background-color: #08b295;
  color: #fff;
}
#operationVideo .robot-state-stage .stage-container ul li.active span {
  font-size: 13px;
  color: #1a9b85;
}
#operationVideo .robot-state-stage .stage-container ul li.completion div {
  background-image: url("../../../assets/icon/icon_circle_complete.png");
}
#operationVideo .robot-state-stage .stage-container ul li.completion span {
  color: #b4c0cc;
}
#operationVideo .robot-state-stage .stage-container ul li div {
  width: 26px;
  height: 26px;
  margin: 8px auto;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #e8eaec;
  line-height: 1.7em;
  text-align: center;
  color: #b4c0cc;
}
#operationVideo .robot-state-stage .stage-container ul li span {
  color: #b4c0cc;
}
#operationVideo .robot-movement-instructions .instructions-container {
  display: flex;
  align-items: center;
}
#operationVideo
  .robot-movement-instructions
  .instructions-container
  label
  select {
  width: 260px;
  height: 54px;
  margin-right: 6px;
  border: 1px solid #dadfe4;
  border-radius: 3px;
  background-image: url("../../../assets/icon/icon_triangle_down.png");
  background-repeat: no-repeat;
  background-position: 93% center;
}
#operationVideo .robot-movement-instructions .instructions-container .btn {
  margin-right: 20px;
  font-weight: bold;
}
#operationVideo .video-connection .content-container {
  display: flex;
}
#operationVideo .video-connection .content-container .video-screen {
  /* width: 960px; */
  height: 435px;
  background-color: #1c1c1c;
}
#operationVideo .video-connection .content-container .robot-control {
  margin-left: 40px;
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container {
  display: flex;
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container
  .controller {
  position: relative;
  width: 236px;
  height: 236px;
  margin-right: 30px;
  border: 1px solid #dadfe4;
  margin-bottom: 30px;
  background-repeat: no-repeat;
  background-position: center center;
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type1
  .controller.left {
  background-image: url("../../../assets/image/button_video_type1_left_disabled.png");
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type1
  .controller.left.active {
  background-image: url("../../../assets/image/button_video_type1_left.png");
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type1
  .controller.right {
  background-image: url("../../../assets/image/button_video_type1_right_disabled.png");
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type1
  .controller.right.active {
  background-image: url("../../../assets/image/button_video_type1_right.png");
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type2
  .controller {
  background-image: url("../../../assets/image/image_video_controller_bg_type2.png");
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type2
  .controller
  .direction-key {
  position: absolute;
  width: 54px;
  height: 54px;
  background-repeat: no-repeat;
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type2
  .controller
  .direction-key.up {
  top: 7%;
  left: 39%;
  background-image: url("../../../assets/image/button_video_type2_up_disabled.png");
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type2
  .controller
  .direction-key.up.active {
  background-image: url("../../../assets/image/button_video_type2_up.png");
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type2
  .controller
  .direction-key.bottom {
  left: 39%;
  bottom: 7%;
  background-image: url("../../../assets/image/button_video_type2_down_disabled.png");
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type2
  .controller
  .direction-key.bottom.active {
  background-image: url("../../../assets/image/button_video_type2_down.png");
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type2
  .controller
  .direction-key.left {
  top: 39%;
  right: 7%;
  background-image: url("../../../assets/image/button_video_type2_right_disabled.png");
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type2
  .controller
  .direction-key.left.active {
  background-image: url("../../../assets/image/button_video_type2_right.png");
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type2
  .controller
  .direction-key.right {
  top: 39%;
  left: 7%;
  background-image: url("../../../assets/image/button_video_type2_left_disabled.png");
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type2
  .controller
  .direction-key.right.active {
  background-image: url("../../../assets/image/button_video_type2_left.png");
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type3
  .controller {
  background-image: url("../../../assets/image/image_video_controller_bg_type3.png");
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type3
  .controller.left.active {
  background-image: url("../../../assets/image/button_video_type3_right.png");
}
#operationVideo
  .video-connection
  .content-container
  .robot-control
  .controller-container.type3
  .controller.right.active {
  background-image: url("../../../assets/image/button_video_type3_right.png");
}
#operationVideo .video-connection .toggle-switch-container {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
#operationVideo .video-connection .toggle-switch-container .toggle-switch {
  position: relative;
  display: block;
  width: 34px;
  height: 14px;
  margin-right: 10px;
  border-radius: 7px;
  background-color: #b4c0cc;
  cursor: pointer;
  /* 토글 버튼 */
}
#operationVideo
  .video-connection
  .toggle-switch-container
  .toggle-switch
  .toggle-button {
  /* 버튼은 토글보다 작아야함  */
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.3);
}
#operationVideo
  .video-connection
  .toggle-switch-container.active
  .toggle-switch {
  background-color: #6be6d2;
}
#operationVideo
  .video-connection
  .toggle-switch-container.active
  .toggle-button {
  background-color: #1a9b85;
}
#operationVideo .video-connection.homecare-robot .content-container {
  flex-direction: column;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container {
  gap: 8px;
  position: absolute;
  right: 24px;
  bottom: 14px;
  display: flex;
  align-items: flex-start;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .lds-grid {
  display: none;
  position: relative;
  width: 80px;
  height: 80px;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .lds-grid
  div {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #505050;
  animation: lds-grid 1.2s linear infinite;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .lds-grid
  div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .lds-grid
  div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .lds-grid
  div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .lds-grid
  div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .lds-grid
  div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .lds-grid
  div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .lds-grid
  div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .lds-grid
  div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .lds-grid
  div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  #closeSelf {
  display: none;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .btn-call:first-child {
  margin: 0;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .btn-call
  i {
  display: block;
  width: 48px;
  height: 48px;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .btn-call.connection
  i {
  background-image: url("../../../assets/image/button_call_on.png");
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .btn-call.disconnect
  i {
  background-image: url("../../../assets/image/button_call_off.png");
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .btn-call
  span {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: #fff;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .forced-connection
  .toggle-switch-container {
  margin-top: 0;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .forced-connection
  .toggle-switch-container
  .toggle-switch {
  width: 146px;
  height: 52px;
  margin-right: 0;
  padding-right: 20px;
  line-height: 52px;
  border-radius: 26px;
  background-color: #3c3c3c;
  text-align: right;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .video-screen
  .btn-container
  .forced-connection
  .toggle-switch-container
  .toggle-switch
  .toggle-button {
  left: 0;
  background-image: url("../../../assets/image/button_call_sos.png");
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .title-container {
  position: absolute;
  display: flex;
  gap: 32px;
  animation: fadein 3s;
  -moz-animation: fadein 3s;
  -webkit-animation: fadein 3s;
  -o-animation: fadein 3s;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .title-container
  .mrs-logo {
  width: 96px;
  height: 96px;
  background: url("../../../assets/image/image_marosol_logo.png") no-repeat;
  background-size: cover;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .title-container
  .title-box {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .title-container
  .title-box
  > h1 {
  font-size: 45px;
  font-weight: 600;
  color: #f5f9ff;
  opacity: 0.9;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .title-container
  .title-box
  > p {
  font-size: 16px;
  font-weight: 400;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .calling-container {
  position: absolute;
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  animation: fadein 1.5s;
  -moz-animation: fadein 1.5s;
  -webkit-animation: fadein 1.5s;
  -o-animation: fadein 1.5s;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .calling-container
  .calling-title-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .calling-container
  .calling-title-box
  h1 {
  font-size: 34px;
  font-weight: 600;
  color: #f5f9ff;
  opacity: 0.9;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .calling-container
  .calling-title-box
  p {
  font-size: 16px;
  font-weight: 400;
  color: #e0ecff;
  opacity: 0.8;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .calling-container
  .user-profile-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding-right: 16px;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .calling-container
  .user-profile-box
  div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 144px;
  border-radius: 50%;
  background: #7e47eb;
  font-size: 48px;
  color: #f5f9ff;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .calling-container
  .user-profile-box
  span {
  color: #f5f9ff;
  opacity: 0.9;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .calling-container
  .lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .calling-container
  .lds-ripple
  div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .calling-container
  .lds-ripple
  div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .called-container {
  position: absolute;
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  animation: fadein 0.5s;
  -moz-animation: fadein 0.5s;
  -webkit-animation: fadein 0.5s;
  -o-animation: fadein 0.5s;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .called-container
  .called-title-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .called-container
  .called-title-box
  h1 {
  font-size: 34px;
  font-weight: 600;
  color: #f5f9ff;
  opacity: 0.9;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .called-container
  .none-user-profile-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .called-container
  .none-user-profile-box
  div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 144px;
  border-radius: 50%;
  background: #555555;
  font-size: 48px;
  color: #f5f9ff;
}
#operationVideo
  .video-connection.homecare-robot
  .content-container
  .called-container
  .none-user-profile-box
  span {
  color: #f5f9ff;
  opacity: 0.9;
}

#mainVideo {
  width: 100%;
  height: 100%;
  background-color: #000;
}
#localVideo {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #000;
}
