.card-menu-on {
  animation: cardMenuOn 0.5s ease-in-out forwards;
}
@keyframes cardMenuOn {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.card-menu-off {
  animation: cardMenuOff 0.5s ease-in-out forwards;
}
@keyframes cardMenuOff {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.total-card-menu-on {
  animation: totalCardMenuOn 0.5s ease-in-out forwards;
}
@keyframes totalCardMenuOn {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.total-card-menu-off {
  animation: totalCardMenuOff 0.5s ease-in-out forwards;
}
@keyframes totalCardMenuOff {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-94%, 0);
  }
}

.robot-menu-on {
  animation: robotMenuOn 0.5s ease-in-out forwards;
}
@keyframes robotMenuOn {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.robot-menu-off {
  animation: robotMenuOff 0.5s ease-in-out forwards;
}
@keyframes robotMenuOff {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}

#icon-red-pulse {
  border-radius: 50%;
  animation: red-pulse 2s ease infinite;
}
@keyframes red-pulse {
  0% {
    box-shadow: red 0 0 0 0;
  }
  75% {
    box-shadow: #ff69b400 0 0 0 16px;
  }
}

#icon-orange-pulse {
  border-radius: 50%;
  animation: orange-pulse 2s ease infinite;
}
@keyframes orange-pulse {
  0% {
    box-shadow: orange 0 0 0 0;
  }
  75% {
    box-shadow: #ff69b400 0 0 0 16px;
  }
}

#icon-green-pulse {
  border-radius: 50%;
  animation: green-pulse 2s ease infinite;
}
@keyframes green-pulse {
  0% {
    box-shadow: #15803d 0 0 0 0;
  }
  75% {
    box-shadow: #ff69b400 0 0 0 16px;
  }
}

#icon-gray-pulse {
  border-radius: 50%;
  animation: gray-pulse 2s ease infinite;
}
@keyframes gray-pulse {
  0% {
    box-shadow: #7c7c7c 0 0 0 0;
  }
  75% {
    box-shadow: #ff69b400 0 0 0 16px;
  }
}

@keyframes scale {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.05;
  }
  100% {
    scale: 1;
  }
}

.scale {
  animation: scale 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-effect {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes spinCircle {
  from {
    transform: translate(0, 0) rotate(0);
  }
  to {
    transform: translate(0, 0) rotate(360deg);
  }
}

.loading-effect {
  animation: spinCircle 1.5s linear infinite;
}

@keyframes skeleton {
  0% {
    stroke: #00000009;
  }

  50% {
    stroke: #00000015;
  }

  100% {
    stroke: #00000009;
  }
}

.skeleton {
  animation: skeleton 1s infinite ease-in-out;
}
