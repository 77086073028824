.on_time {
    font-size: 9px;

    .ant-picker-input > input {
        font-size: 6px;
    }
}

.table-red {
    background-color: rgb(8, 178, 149, 0.3);
}


.ant-select-selector {
    .ant-select-selection-item {
        font-size: 7px;
    }
}


.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-size: 13px;
}

.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 90%;
    background: #fff;
}

.dndflow aside {
    /*border-right: 1px solid #eee;*/
    padding-left: 10px;
    padding-right: 15px;
    font-size: 12px;
    /*background: #fcfcfc;*/
}

.dndflow aside .description {
    margin-bottom: 10px;
}

.dndflow .dndnode {
    height: 60px;
    padding: 4px;
    border: 1px solid #1a192b;
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

.dndflow .dndnode.input {
    border-color: #0041d0;
}

.dndflow .dndnode.output {
    border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
    margin-top: 143px;
    flex-grow: 1;
    height: 84vh;
    width: 73%;
    display: flex;
    align-self: center;
}

.dndflow .selectall {
    margin-top: 10px;
}

@media screen and (min-width: 768px) {
    .dndflow {
        /*flex-direction: row;*/
    }

    .dndflow aside {
        max-width: 280px;
    }
}

.react-flow__node-default.selectable.selected,
.react-flow__node-default.selectable:focus,
.react-flow__node-default.selectable:focus-visible,
.react-flow__node-input.selectable.selected,
.react-flow__node-input.selectable:focus,
.react-flow__node-input.selectable:focus-visible,
.react-flow__node-output.selectable.selected,
.react-flow__node-output.selectable:focus,
.react-flow__node-output.selectable:focus-visible,
.react-flow__node-group.selectable.selected,
.react-flow__node-group.selectable:focus,
.react-flow__node-group.selectable:focus-visible {
    box-shadow: 0 0 0 1px #3498db !important;
}

.ant-input {
    border-radius: 4px;
    padding: 4px;
}

.ant-select-single.ant-select-sm .ant-select-selector {
    font-size: 9px;
    text-align: center;
    line-height: 0px !important;
}

.ant-select-selection-item {
    font-size: 9px;
}

.ant-table-thead .ant-table-cell {
    background-color: #e5e5e5 !important;
}

.lineTypeS .ant-select-selection-item {
    font-size: 13px;
}

.wf-header {
    display: flex;
    z-index: 99;
    position: fixed;
    align-items: center;
    top: 95px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(229, 231, 235, 0.5);
    width: 95%;
}

.wf-header-left {
    width: 60%;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.wf-header-right {
    float: right;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
}

.wf-body-left {
    margin-top: 142px;
    margin-left: 10px;
    width: 13%;
    border-right: 1px solid rgb(229, 231, 235, 0.5);
    position: fixed;
    z-index: 90;
}

.wf-body-left-child {
}

.wf-body-left-contents {
    padding-left: 5px !important;
    height: 75vh;
    z-index: 10;
    width: 100%;
    overflow: auto;
}

.wf-body-right-contents {
    height: 73vh;
    z-index: 10;
    width: 100%;
    overflow: auto;
}

.wf-body-right {
    margin-top: 142px;
    margin-left: 10px;
    width: 13%;
    border-left: 1px solid rgb(229, 231, 235, 0.5);
    position: fixed;
    z-index: 99;
    right: 0;
}

.wf-body-right-top {
    padding: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgb(229, 231, 235, 0.5);
}

.wf-body-right-bottom {

}

.wf-body-right-top-title {
    margin-right: 15px;
}

.ant-tabs-nav-wrap {
    padding: 10px 10px 0 10px;
}

.ant-collapse-large > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
}

.ant-collapse-large > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 5px;
}

.ant-collapse .ant-collapse-content {
    border: none;
}

.ant-collapse > .ant-collapse-item {
    border: none;
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 6px 6px 6px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 10px 6px 5px 6px;
}
