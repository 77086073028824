#content {
  width: 250px;
  font-size: 15px;
  padding: 20px;
}

#title {
  font-weight: 600;
}

#address {
  font-size: 12px;
}

#btn {
  display: flex;
  margin-top: 12px;
  color: #08b295;
  font-weight: 600;
}

#robotBtn {
  padding-right: 10px;
}
