.ant-select-selection-item {
  font-size: 12px;
}

.scheduleSwitch {
  -webkit-appearance: button;
  background-color: #ccc;
  background-image: none;
}

.buttonSwitchPrimary {
  -webkit-appearance: button;
  background-color: #26bfa1;
  background-image: none;
  margin-right: 5px;
}
.buttonSwitch {
  -webkit-appearance: button;
  background-color: #fff;
  background-image: none;
  margin-right: 5px;
}

.wrap-btn {
  /* right: 0;
  position: absolute;
  margin-right: 30px; */
  display: flex;
  justify-content: flex-end;
}

.ant-picker-dropdown {
  button {
    background-color: #26bfa1;
  }
}

.ant-btn-primary {
  background-color: #26bfa1;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
  background: #08b29510;
  /*border: 1px solid #26bfa1;*/
}

/*!* 일요일 날짜 빨간색 *!*/
.fc-day-sun a {
  color: red;
  text-decoration: none;
}

/*!* 토요일 날짜 파란색 *!*/
.fc-day-sat a {
  color: blue;
  text-decoration: none;
}

.fc-event-selected {
  background-color: #26bfa1;
}

.fc-event-selected::after {
  background: none;
}

.fc-popover-body {
  max-height: 150px;
  overflow-y: scroll;
}
